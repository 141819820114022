<template>
    <div class="container">
        <div class="form-search-box">
            <div class="flex-row-between" style="margin-top:10px">
                <div class="flex-row-start">
                    <div class="flex-row-start" style="margin-right:20px">
                        <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">门店信息:</div>
                        <el-input size="mini" style="width:200px" v-model="search.var" placeholder="连锁代码/编号/代码-编号" />
                    </div>
                    <div class="flex-row-start" style="margin-right:20px">
                        <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">上级门店:</div>
                        <el-select size="mini" style="width:200px" v-model="search.pid" filterable placeholder="请选择上级门店">
                            <el-option v-for="(item,index) in pidList" :key="index" :label="item.abbreviation" :value="item.shop_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex-row-start" style="margin-right:20px">
                        <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">门店名称:</div>
                        <el-input size="mini" style="width:200px" v-model="search.name" placeholder="输入门店名称/简称" />
                    </div>
                </div>
                <div class="flex-row-start">
                    <a-button @click="searchAct" type="primary">查询</a-button>
                    <a-button @click="cancelAct1" class="ml10">取消</a-button>
                    <!-- <el-button style="margin-right:10px" type="primary">查询</el-button>
                    <el-button type="primary">取消</el-button> -->
                </div>
            </div>
        </div>
        <div class="table-container mt20">
            <div class="wxb-table-white">
                <el-table v-loading="loading" ref="multipleTable" :data="datas" fit highlight-current-row>
                    <el-table-column align="center" label="ID编号" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.shop_id? scope.row.shop_id:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="门店信息" minWidth="520">
                        <template slot-scope="scope">
                            <div class="flex-row-start" style="text-align: left;">
                                <img class="img_tab" v-if="scope.row.logo" :src="BASE_IMG_URL +scope.row.logo" />
                                <img class="img_tab" v-else src="../../assets/image/logo48@2x.png" />
                                <div class="ml20">
                                    <div class="ft14 flex-row-start">
                                        <div style="width:70px">连锁代码 :</div>
                                        <div style="width:100px">{{scope.row.shop_code?scope.row.shop_code:'-'}}</div>
                                    </div>
                                    <div class="ft14 flex-row-start" style="margin:10px 0">
                                        <div style="width:70px">门店编号 :</div>
                                        <div style="width:100px">{{scope.row.shop_number?scope.row.shop_number:'-'}}</div>
                                    </div>
                                    <div class="ft14 flex-row-start">
                                        <div style="width:70px">上级门店 :</div>
                                        <div style="width:100px">{{scope.row.pid_name?scope.row.pid_name:'无'}}</div>
                                    </div>
                                </div>
                                <div class="ml10">
                                    <div class="ft14 flex-row-start">
                                        <div style="width:70px">门店全称 :</div>
                                        <div>{{scope.row.name?scope.row.name:'-'}}</div>
                                    </div>
                                    <div class="ft14 flex-row-start" style="margin:10px 0">
                                        <div style="width:70px">门店简称 :</div>
                                        <div>{{scope.row.abbreviation?scope.row.abbreviation:'-'}}</div>
                                    </div>
                                    <div class="ft14 flex-row-start">
                                        <div style="width:70px">门店级别 :</div>
                                        <div>{{scope.row.level ==1?'总部':
                                scope.row.level ==2?'事业部':
                                scope.row.level ==3?'地区':
                                scope.row.level ==4?'区域':
                                scope.row.level ==5?'门店':
                                '-'}}</div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="门店地址" minWidth="300">
                        <template slot-scope="scope">
                            <div style="text-align: left;">
                                <div class="ft14 flex-row-start">
                                    <div style="width:60px">电话 :</div>
                                    <div>{{scope.row.mobile?scope.row.mobile:'-'}}</div>
                                </div>
                                <div class="ft14 flex-row-start" style="margin:10px 0">
                                    <div style="width:60px">地址 :</div>
                                    <div>{{scope.row.province_name}}{{scope.row.city_name}}{{scope.row.area_name}}{{scope.row.address}}</div>
                                </div>
                                <div class="ft14 flex-row-start">
                                    <div style="width:60px">创建人 :</div>
                                    <div>{{scope.row.create_user_name ?scope.row.create_user_name:'-'}}</div>
                                </div>
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column label="客服信息" minWidth="300">
                        <template slot-scope="scope">
                            <div style="text-align: left;">
                                <div v-if="scope.row.customer_data" class="flex-row-start">
                                    <img class="img_tab" v-if="scope.row.customer_data.or_code" :src="BASE_IMG_URL +scope.row.customer_data.or_code" />
                                    <img class="img_tab" v-else src="../../assets/image/kefu.png" />
                                    <div class="ml10">
                                        <div class="flex-row-start">
                                            <div style="width:80px">客服名称 :</div>
                                            <div class="ellipsis">{{ scope.row.customer_data &&scope.row.customer_data.name ? scope.row.customer_data.name : "暂无" }} </div>
                                        </div>
                                        <div class="flex-row-start" style="margin-top:10px">
                                            <div style="width:80px">客服手机号 :</div>
                                            <div>{{ scope.row.customer_data &&scope.row.customer_data.mobile? scope.row.customer_data.mobile : "暂无" }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>暂无客服信息</div>

                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="有效期" minWidth="300">
                        <template slot-scope="scope">
                            <div style="text-align: left;">
                                <div class="flex-row-start">
                                    <div style="width:80px">有效期起 :</div>
                                    <div>{{scope.row.start_time?scope.row.start_time:'-'}}</div>
                                </div>
                                <div class="flex-row-start" style="margin:10px 0">
                                    <div style="width:80px">有效期止 :</div>
                                    <div>{{scope.row.end_time?scope.row.end_time:'-'}}</div>
                                </div>
                                <div class="flex-row-start">
                                    <div style="width:80px">授权状态 :</div>
                                    <div>{{scope.row.status_name?scope.row.status_name:'-'}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" minWidth="80">
                        <template slot-scope="scope">
                            <a-dropdown placement="bottomRight">
                                <span class="more-act">
                                    <i class="iconfont iconmore_gray"></i>
                                </span>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="manageIt(scope.row)">
                                            <i class="iconfont ft14 iconshop_operation01"></i>
                                            <span class="ml10">进入管理</span>
                                        </a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="openAdd(scope.row)">
                                            <i class="iconfont ft14 iconshop_operation01"></i>
                                            <span class="ml10">编辑</span>
                                        </a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="renewMiniappAct(scope.row)">
                                            <i class="iconfont ft14 iconnimipgrogram_operation04"></i>
                                            <span class="ml10">续费</span>
                                        </a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="appointKefuAct(scope.row)">
                                            <i class="iconfont ft14 iconassignment"></i>
                                            <span class="ml10">指定客服</span>
                                        </a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="displayExpire(scope.row)">
                                            <i class="iconfont ft14 iconassignment"></i>
                                            <span class="ml10">显示到期日期</span>
                                        </a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="paymentSettingAct(scope.row)">
                                            <i class="iconfont ft14 iconshezhi1"></i>
                                            <span class="ml10">支付设置</span>
                                        </a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;">
                                            <i class="iconfont ft14 iconyulan"></i>
                                            <span class="ml10">预览</span>
                                        </a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="delShopAct(scope.row)">
                                            <i class="iconfont ft14 icondelete"></i>
                                            <span class="ml10">删除</span>
                                        </a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                            <!-- <el-dropdown>
                                    <span class="more-act">
                                        <i class="iconfont iconmore_gray"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;" @click="manageIt(scope.row)">
                                                <i class="iconfont ft14 iconshop_operation01"></i>
                                                <span class="ml10">进入管理</span>
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;" @click="manageIt(scope.row)">
                                                <i class="iconfont ft14 iconshop_operation01"></i>
                                                <span class="ml10">编辑</span>
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;" @click.stop="renewMiniappAct(scope.row)">
                                                <i class="iconfont ft14 iconnimipgrogram_operation04"></i>
                                                <span class="ml10">续费</span>
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;" @click="appointKefuAct(scope.row)">
                                                <i class="iconfont ft14 iconassignment"></i>
                                                <span class="ml10">指定客服</span>
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;" @click="displayExpire(scope.row)">
                                                <i class="iconfont ft14 iconassignment"></i>
                                                <span class="ml10">显示到期日期</span>
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;" @click="paymentSettingAct(scope.row)">
                                                <i class="iconfont ft14 iconshezhi1"></i>
                                                <span class="ml10">支付设置</span>
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;">
                                                <i class="iconfont ft14 iconyulan"></i>
                                                <span class="ml10">预览</span>
                                            </a>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <a class="menu-act" href="javascript:;" @click="delShopAct(record)">
                                                <i class="iconfont ft14 icondelete"></i>
                                                <span class="ml10">删除</span>
                                            </a>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown> -->
                        </template>
                    </el-table-column>

                </el-table>
            </div>
            <pagination v-show="pagination.total>0" style="margin-top: 15px;" :total="pagination.total" :page.sync="pagination.current" :limit.sync="pagination.pageSize" @pagination="getLists(false)" />
        </div>
        <div v-if="renewMiniappLoading">
            <renew-miniapp :shop_id="shop_id" :shop_name="shop_name" :customer_service_id="customer_service_id" :visible="renewMiniappLoading" @cancel="cancelRenewMiniapp" @ok="okRenewMiniapp"></renew-miniapp>
        </div>
        <div v-if="displayExpireLoading">
            <display-expire :shop_id="shop_id" :time_state="time_state" :customer_service_id="customer_service_id" :visible="displayExpireLoading" @cancel="cancelDisplayExpire" @ok="okDisplayExpire"></display-expire>
        </div>
        <div v-if="appointKefuLoading">
            <appoint-kefu :shop_id="shop_id" :customer_service_id="customer_service_id" :visible="appointKefuLoading" @cancel="cancelAppointKefu" @ok="okAppointKefu"></appoint-kefu>
        </div>
        <div v-if="paymentSettingLoading">
            <payment-setting :shop_id="shop_id" :visible="paymentSettingLoading" @cancel="cancelPaymentSetting" @ok="okPaymentSetting"></payment-setting>
        </div>
        <addShop ref="addShop"></addShop>
    </div>
</template>

<script>
import addShop from "./components/add-shop.vue";
import Pagination from '@/components/Pagination'
import { listMixin } from "../../common/mixin/list.js";
import renewMiniapp from "./components/renew.vue";
import displayExpire from "./components/display.vue";
import appointKefu from "./components/index/modal/appointKefu.vue";
import paymentSetting from "./components/index/modal/paymentSetting.vue";
export default {
    mixins: [listMixin],
    components: {
        renewMiniapp,
        appointKefu,
        paymentSetting,
        displayExpire,
        Pagination,
        addShop
    },
    data() {
        return {
            BASE_IMG_URL:window.global_config.BASE_IMG_URL,
            loading: false,
            renewMiniappLoading: false,
            displayExpireLoading: false,
            appointKefuLoading: false,
            paymentSettingLoading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                var: "",
                pid: "",
                name: ""
            },
            shop_id: 0,
            time_state: '',
            shop_name: '',
            customer_service_id: 0,
            app_list: [],
            datas: [],
            pidList: [],
        };
    },
    created() {
        this.getPid()
    },
    methods: {
        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http
                .api("api/store/listStore", {
                    var: this.search.var,
                    pid: this.search.pid,
                    name: this.search.name,
                    // app_id: this.$store.state.test.app_id,
                    status: '2',
                    limit: this.pagination.pageSize,
                    page: this.pagination.current,
                })
                .then((res) => {
                    this.datas = res.data.data;
                    this.pagination.total = res.data.total;
                    // this.app_list = res.app_list;
                    this.loading = false;
                })
                .catch((res) => {
                    this.loading = false;
                });
        },
        getPid() {
            this.$http
                .api("api/store/listStore", {
                    // app_id: this.$store.state.test.app_id,
                    status_type: '1',
                    limit: 100,
                    page: 1,
                })
                .then((res) => {
                    this.pidList = res.data.data;
                })
        },
        cancelAct1() {
            this.search.var = ''
            this.search.pid = ''
            this.search.name = ''
            this.pagination.current = 1;
            this.getLists();
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },

        manageIt(record) {
            this.$message.info('开发中~');
            return
            if (record.app.status == 1) {
                let base_url = record.app.production_address;
                window.open(
                    base_url +
                    "auth?shop_token=" +
                    encodeURIComponent(record.relation.shop_token)
                );
            } else {
                let base_url = record.app.development_address;
                window.open(
                    base_url +
                    "auth?shop_token=" +
                    encodeURIComponent(record.relation.shop_token)
                );
            }
        },

        openAdd(row) {
            this.$refs.addShop.open('edit', row)
        },

        renewMiniappAct(record) {
            this.shop_id = record.shop_id;
            this.shop_name = record.name;
            this.renewMiniappLoading = true;
        },
        cancelRenewMiniapp() {
            this.renewMiniappLoading = false;
        },
        okRenewMiniapp() {
            this.renewMiniappLoading = false;
            this.getLists();
        },

        appointKefuAct(record) {
            this.shop_id = record.shop_id;
            this.customer_service_id = record.customer_service_id;
            this.appointKefuLoading = true;
        },
        cancelAppointKefu() {
            this.appointKefuLoading = false;
        },
        okAppointKefu() {
            this.appointKefuLoading = false;
            this.getLists();
        },

        displayExpire(record) {
            this.shop_id = record.shop_id;
            this.time_state = record.time_state ? record.time_state : 1;
            this.displayExpireLoading = true;
        },
        cancelDisplayExpire() {
            this.displayExpireLoading = false;
        },
        okDisplayExpire() {
            this.displayExpireLoading = false;
            this.getLists();
        },

        paymentSettingAct(record) {
            this.shop_id = record.shop_id;
            this.paymentSettingLoading = true;
        },
        cancelPaymentSetting() {
            this.paymentSettingLoading = false;
        },
        okPaymentSetting() {
            this.paymentSettingLoading = false;
            this.getLists();
        },

        delShopAct(record) {
            this.$confirm({
                title: "删除后数据无法恢复，确认继续删除吗？",
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http
                            .api("api/store/del", {
                                id: record.shop_id,
                            })
                            .then((res) => {
                                this.$message.success("删除成功");
                                this.getLists();
                            })
                            .catch((res) => {
                                console.log(res);
                            })
                            .finally(() => {
                                resolve();
                            });
                    });
                },
            });
        },
        onEdit(type) {
            this.$refs.addindex.open()
        },
    },
};
</script>

<style>
.miniapp-table-logo img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    border: 2px solid #ffffff;
}

.kefu-table-logo img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}

.img_tab {
    width: 50px;
    height: 50px;
}
</style>
